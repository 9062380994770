import DateOfBirth from "./DateOfBirth";
import { useState } from "react";
import {
	dobVerify,
} from "../../repository/parametersRepository";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import useAccessTokenState from "../../common/useAccessTokenState";
import { useHistory } from 'react-router-dom';
import useSearch from '../../common/useSearch';

export default function DateOfBirthPage() {
	const { qr: code } = useSearch();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [alert, setAlert] = useState({
		open: false,
		status: null,
		message: null,
		invocationId: null,
	});
	const { setAccessToken } = useAccessTokenState();

	async function submitDob(dob) {
		const result = await dobVerify(code, dob);
		setIsLoading(false);
		if (result.ok) {
			setAccessToken(result.access_token);
			history.push(`/DataEntry?qr=${code}`);
		} else {
			setAlert({
				open: true,
				status: result.status,
				message: result.statusText,
				invocationId: result.invocation_id,
			});
		}
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<DateOfBirth
				alert={alert}
				loading={isLoading}
				handleDateOfBirthSubmit={async (dob) => {
					setIsLoading(true);
					await submitDob(dob);
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}