import {
	Paper,
	Radio,
	RadioGroup,
	FormControlLabel,
	Checkbox,
	Select,
	InputLabel,
	FormControl,
	TextField,
	MenuItem,
	Button,
	Grid,
	makeStyles,
	CircularProgress,
	FormGroup,
	FormHelperText,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import iboxLogo from "../../assets/iboxLogo2.png";
import { useReducer, useState, useEffect, useRef } from "react";
import * as validation from "../../common/validation";
import * as Constants from "../../common/constants";
import SuccessModal from "./SuccessModal";

const useStyles = makeStyles({
	image: {
		margin: 10,
		alignSelf: "center",
		width: 200,
	},
	section: {
		borderBottom: "solid",
		borderWidth: "2px",
		borderColor: "black",
	},
	subList: {
		marginLeft: "20px",
	},
	errorBox: {
		marginLeft: "15px",
		marginRight: "15px",
	},
	floatingLabelStyle: {
		"& label": {
			color: "#cb333b",
		},
	},
	selectLabelStyle: {
		color: "#cb333b",
	},
	smallLabel: {
		fontSize: "11pt",
	},
});

const reducer = (state, action) => {
	var newObj = Object.assign({}, state);
	newObj[action.type] = action.payload;
	return newObj;
};

function IBoxDataPoints({
	successDialogOpen,
	iBoxParameters,
	loading,
	alert,
	onInvalid,
	onSubmit,
}) {
	const classes = useStyles();

	const [state, dispatch] = useReducer(reducer, iBoxParameters.parameters);

	const [okraId, setOkraId] = useState(iBoxParameters.okra_id);
	const [errorDateOfEgfrTaken, setErrorDateOfEgfrTaken] = useState(false);
	const [errorDateOfProteinuriaTaken, setErrorDateOfProteinuriaTaken] =
		useState(false);
	const [errorDateOfDsaTaken, setErrorDateOfDsaTaken] = useState(false);
	const [errorDateOfHistologyTaken, setErrorDateOfHistologyTaken] =
		useState(false);
	const [errorEgfr, setErrorEgfr] = useState(false);
	const [errorCreatinine, setErrorCreatinine] = useState(false);
	const [errorProteinuriaInGG, setErrorProteinuriaInGG] = useState(false);
	const [errorDsaMfi, setErrorDsaMfi] = useState(false);
	const [errorDiagnosis, setErrorDiagnosis] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	// Setting null values from parameters api
	useEffect(() => {
		if (!state.dsa_status) {
			dispatch({ type: Constants.DSA_STATUS, payload: "not_available" });
		}

		if (!state.histology_status) {
			dispatch({ type: Constants.HISTOLOGY_STATUS, payload: "not_available" });
		}
	}, []);

	// If any field has an error, the submit button disables
	useEffect(() => {
		setButtonDisabled(
			errorEgfr === true ||
				errorProteinuriaInGG === true ||
				errorDsaMfi === true ||
				errorDateOfHistologyTaken === true ||
				errorDateOfDsaTaken === true ||
				errorDateOfEgfrTaken === true ||
				errorDateOfProteinuriaTaken === true ||
				errorEgfr === true
		);
	}, [
		errorEgfr,
		errorProteinuriaInGG,
		errorDsaMfi,
		errorDateOfHistologyTaken,
		errorDateOfDsaTaken,
		errorDateOfEgfrTaken,
		errorDateOfProteinuriaTaken,
	]);

	// Another effect for the diagnosis fields since they all can't be false/null
	useEffect(() => {
		if (
			state.histology_status === "diagnosis_available" &&
			state.diagnosis_abmr !== true &&
			state.diagnosis_tcmr !== true &&
			state.diagnosis_recurrence !== true &&
			state.diagnosis_bk !== true &&
			state.diagnosis_cni !== true &&
			state.diagnosis_aki !== true &&
			state.diagnosis_other !== true
		) {
			setErrorDiagnosis(true);
			setButtonDisabled(true);
		} else {
			setErrorDiagnosis(false);
			setButtonDisabled(false);
		}
	}, [
		state.diagnosis_abmr,
		state.diagnosis_tcmr,
		state.diagnosis_recurrence,
		state.diagnosis_bk,
		state.diagnosis_cni,
		state.diagnosis_aki,
		state.diagnosis_other,
		state.histology_status,
	]);

	function handleChange(type, value) {
		switch (type) {
			case Constants.DATE_OF_EGFR_TAKEN:
				dispatch({ type: type, payload: value });
				if (
					validation.validateDate(iBoxParameters.allosure_draw_date, value, 7)
				) {
					return false;
				} else {
					return true;
				}
			case Constants.DATE_OF_PROTEINURIA_TAKEN:
				dispatch({ type: type, payload: value });
				if (
					validation.validateDate(iBoxParameters.allosure_draw_date, value, 7)
				) {
					return false;
				} else {
					return true;
				}
			case Constants.DATE_OF_DSA_TAKEN:
				dispatch({ type: type, payload: value });
				if (
					validation.validateDate(iBoxParameters.allosure_draw_date, value, 31)
				) {
					return false;
				} else {
					return true;
				}
			case Constants.DATE_OF_HISTOLOGY_TAKEN:
				dispatch({ type: type, payload: value });
				if (
					validation.validateDate(iBoxParameters.allosure_draw_date, value, 31)
				) {
					return false;
				} else {
					return true;
				}
			case Constants.EGFR:
				dispatch({ type: type, payload: value });
				if (validation.validateNumber(value, 0, 120, 1)) {
					return false;
				} else {
					return true;
				}
			case Constants.CREATININE:
				dispatch({ type: type, payload: value });
				if (validation.validateNumber(value, null, null, 2)) {
					return false;
				} else {
					return true;
				}
			case Constants.PROTEINURIA_IN_G_G:
				dispatch({ type: type, payload: value });
				if (validation.validateNumber(value, 0, 12, 3)) {
					return false;
				} else {
					return true;
				}
			case Constants.DSA_MFI:
				dispatch({ type: type, payload: value });
				if (validation.validateNumber(value, 0, 20000, 0)) {
					return false;
				} else {
					return true;
				}
			default:
				break;
		}
	}

	return (
		<Paper>
			{alert.open ? (
				<Alert variant="filled" severity="error" className={classes.errorBox}>
					<AlertTitle>Error</AlertTitle>
					<div>{alert.message}</div>
					{alert.invocationId ? (
						<div>Correlation ID: {alert.invocationId}</div>
					) : (
						""
					)}
				</Alert>
			) : (
				[]
			)}

			<Grid>
				<img className={classes.image} src={iboxLogo} />
				<form
					onInvalid={onInvalid}
					onSubmit={async (event) => {
						await onSubmit({ event, okraId, state, iBoxParameters });
					}}
				>
					<Grid className={classes.section}>
						<SuccessModal open={successDialogOpen} />

						<TextField
							name={Constants.ACCESSION_ID}
							disabled
							label="Accession ID"
							type="text"
							value={iBoxParameters.accession_id}
						/>
						<TextField
							name={Constants.ALLOSURE_DRAW_DATE}
							disabled
							label="Date of AlloSure Collection"
							type="date"
							value={iBoxParameters.allosure_draw_date}
						/>
						<TextField
							name={Constants.OKRA_ID}
							label="Study/Patient ID"
							type="text"
							value={okraId}
							onChange={(e) => setOkraId(e.target.value)}
							className={classes.floatingLabelStyle}
						/>
					</Grid>
					<Grid className={classes.section}>
						<b>Estimated GFR (eGFR, using MDRD)</b>
						<TextField
							name={Constants.DATE_OF_EGFR_TAKEN}
							label="Date of eGFR/Serum Creatinine Collection"
							type="date"
							onChange={(e) => {
								const error = handleChange(
									Constants.DATE_OF_EGFR_TAKEN,
									e.target.value
								);
								setErrorDateOfEgfrTaken(error);
							}}
							error={errorDateOfEgfrTaken ? true : false}
							helperText={
								errorDateOfEgfrTaken ? Constants.ERROR_DATE_OF_EGFR_TAKEN : ""
							}
							value={state.date_of_egfr_taken}
							className={classes.floatingLabelStyle}
						/>
						<RadioGroup
							value={state.egfr_status}
							onChange={(e) => {
								// // nulling out demographics so to not ovewrite db values
								if (e.target.value === "calculated") {
									dispatch({
										type: Constants.CREATININE,
										payload: null,
									});

									dispatch({
										type: Constants.IS_BLACK,
										payload: null,
									});

									dispatch({
										type: Constants.AGE,
										payload: null,
									});

									dispatch({
										type: Constants.IS_FEMALE,
										payload: null,
									});

									setErrorCreatinine(false);
								} else {
									dispatch({
										type: Constants.EGFR,
										payload: null,
									});

									setErrorEgfr(false);
								}

								dispatch({
									type: Constants.EGFR_STATUS,
									payload: e.target.value,
								});
							}}
						>
							<FormControlLabel value="egfr" control={<Radio />} label="eGFR" />
							{state.egfr_status === "egfr" ? (
								<TextField
									placeholder=""
									name={Constants.EGFR}
									label="0.0 - 120.0 [mL/min/1.73m]"
									type="text"
									value={state.egfr}
									onChange={(e) => {
										const error = handleChange(Constants.EGFR, e.target.value);
										setErrorEgfr(error);
									}}
									error={errorEgfr}
									helperText={errorEgfr ? Constants.ERROR_EGFR : ""}
									className={classes.floatingLabelStyle}
								/>
							) : (
								[]
							)}
							<FormControlLabel
								value="calculated"
								control={<Radio />}
								label="Serum creatinine & race"
							/>
							{state.egfr_status === "calculated" ? (
								<FormGroup className={classes.subList}>
									<TextField
										name={Constants.CREATININE}
										label="Serum Creatinine (mg/dL)"
										onChange={(e) => {
											dispatch({
												type: Constants.CREATININE,
												payload: e.target.value,
											});
											const error = handleChange(
												Constants.CREATININE,
												e.target.value
											);
											setErrorCreatinine(error);
										}}
										value={state.creatinine}
										error={errorCreatinine}
										helperText={
											errorCreatinine ? Constants.ERROR_CREATININE : ""
										}
										className={classes.floatingLabelStyle}
									/>
									<FormControl id="race" component="fieldset">
										<RadioGroup
											name={Constants.IS_BLACK}
											value={state.is_black}
											onChange={(e) =>
												dispatch({
													type: Constants.IS_BLACK,
													payload: e.target.value === "true" ? true : false,
												})
											}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label="Black"
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label="Other"
											/>
										</RadioGroup>
									</FormControl>
									<TextField
										name={Constants.AGE}
										label="Age"
										onChange={(e) =>
											dispatch({
												type: Constants.AGE,
												payload: e.target.value,
											})
										}
										value={state.age}
										className={classes.floatingLabelStyle}
									/>
									<FormControl id="gender" component="fieldset">
										<RadioGroup
											name={Constants.IS_FEMALE}
											value={state.is_female}
											onChange={(e) =>
												dispatch({
													type: Constants.IS_FEMALE,
													payload: e.target.value === "true" ? true : false,
												})
											}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label="Female"
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label="Male"
											/>
										</RadioGroup>
									</FormControl>
								</FormGroup>
							) : (
								[]
							)}
						</RadioGroup>
					</Grid>
					<Grid className={classes.section}>
						<b>Proteinuria</b>
						<TextField
							name={Constants.DATE_OF_PROTEINURIA_TAKEN}
							label="Date of Urine Collection"
							type="date"
							onChange={(e) => {
								const error = handleChange(
									Constants.DATE_OF_PROTEINURIA_TAKEN,
									e.target.value
								);
								setErrorDateOfProteinuriaTaken(error);
							}}
							error={errorDateOfProteinuriaTaken ? true : false}
							helperText={
								errorDateOfProteinuriaTaken
									? Constants.ERROR_DATE_OF_PROTEINURIA_TAKEN
									: ""
							}
							value={state.date_of_proteinuria_taken}
							className={classes.floatingLabelStyle}
						/>
						<RadioGroup
							value={state.proteinuria_unit}
							onChange={(e) => {
								// nulling out so to not ovewrite db values
								if (e.target.value === "g/g") {
									dispatch({
										type: Constants.PROTEINURIA_DIPSTICK,
										payload: null,
									});
								} else {
									dispatch({
										type: Constants.PROTEINURIA_IN_G_G,
										payload: null,
									});

									setErrorProteinuriaInGG(false);
								}
								dispatch({
									type: Constants.PROTEINURIA_UNIT,
									payload: e.target.value,
								});
							}}
						>
							<FormControlLabel
								value="g/g"
								control={<Radio />}
								label="PCR [g/g]"
							/>
							{state.proteinuria_unit === "g/g" ? (
								<TextField
									name={Constants.PROTEINURIA_IN_G_G}
									label="Urine protein/creatinine ratio (0-12)"
									type="text"
									onChange={(e) => {
										const error = handleChange(
											Constants.PROTEINURIA_IN_G_G,
											e.target.value
										);
										setErrorProteinuriaInGG(error);
									}}
									error={errorProteinuriaInGG}
									value={state.proteinuria_in_g_g}
									helperText={
										errorProteinuriaInGG
											? Constants.ERROR_PROTEINURIA_IN_G_G
											: ""
									}
									className={classes.floatingLabelStyle}
								/>
							) : (
								[]
							)}

							<FormControlLabel
								value="dipstick"
								control={<Radio />}
								label="Dipstick"
							/>
							{state.proteinuria_unit === "dipstick" ? (
								<RadioGroup
									name={Constants.PROTEINURIA_DIPSTICK}
									className={classes.subList}
									value={state.proteinuria_dipstick}
									onChange={(e) =>
										dispatch({
											type: Constants.PROTEINURIA_DIPSTICK,
											payload: e.target.value,
										})
									}
								>
									<FormControlLabel
										value="absence"
										control={<Radio />}
										label="Absence"
									/>
									<FormControlLabel
										value="traces"
										control={<Radio />}
										label="Traces"
									/>
									<FormControlLabel value="+" control={<Radio />} label="+" />
									<FormControlLabel value="++" control={<Radio />} label="++" />
									<FormControlLabel
										value="+++"
										control={<Radio />}
										label="+++"
									/>
									<FormControlLabel
										value="++++"
										control={<Radio />}
										label="++++"
									/>
								</RadioGroup>
							) : (
								[]
							)}
						</RadioGroup>
					</Grid>
					<Grid className={classes.section}>
						<Grid container direction="row" justify="space-between">
							<b>DSA</b>
							<Checkbox
								checked={state.dsa_status === "not_available" ? false : true}
								inputProps={{ "aria-label": "Checkbox A" }}
								onChange={(e) => {
									if (!e.target.checked) {
										dispatch({
											type: Constants.DSA_STATUS,
											payload: "not_available",
										});
									} else {
										if (e.target.checked) {
											dispatch({
												type: Constants.DSA_STATUS,
												payload: "mfi",
											});
										}
									}
								}}
							/>
						</Grid>
						{state.dsa_status !== "not_available" ? (
							<>
								<TextField
									name={Constants.DATE_OF_DSA_TAKEN}
									label="Date of DSA Collection"
									type="date"
									onChange={(e) => {
										const error = handleChange(
											Constants.DATE_OF_DSA_TAKEN,
											e.target.value
										);
										setErrorDateOfDsaTaken(error);
									}}
									error={errorDateOfDsaTaken ? true : false}
									helperText={
										errorDateOfDsaTaken ? Constants.ERROR_DATE_OF_DSA_TAKEN : ""
									}
									value={state.date_of_dsa_taken}
									className={classes.floatingLabelStyle}
								/>
								<RadioGroup
									value={state.dsa_status}
									onChange={(e) =>
										// nulling out so to not ovewrite db values
										{
											if (e.target.value === "mfi") {
												dispatch({
													type: Constants.DSA_BINARY,
													payload: null,
												});
											} else {
												dispatch({
													type: Constants.DSA_MFI,
													payload: null,
												});

												setErrorDsaMfi(false);
											}

											dispatch({
												type: Constants.DSA_STATUS,
												payload: e.target.value,
											});
										}
									}
								>
									<FormControlLabel
										value="mfi"
										control={<Radio />}
										label="MFI"
									/>
									{state.dsa_status === "mfi" ? (
										<TextField
											name={Constants.DSA_MFI}
											label="anti-HLA DSA MFA (0-20000)"
											type="text"
											onChange={(e) => {
												const error = handleChange(
													Constants.DSA_MFI,
													e.target.value
												);
												setErrorDsaMfi(error);
											}}
											value={state.dsa_mfi}
											error={errorDsaMfi}
											helperText={errorDsaMfi ? Constants.ERROR_DSA_MFI : ""}
											className={classes.floatingLabelStyle}
										/>
									) : (
										[]
									)}
									<FormControlLabel
										value="binary"
										control={<Radio />}
										label="Binary"
									/>
									{state.dsa_status === "binary" ? (
										<RadioGroup
											name={Constants.DSA_BINARY}
											className={classes.subList}
											value={state.dsa_binary}
											onChange={(e) =>
												dispatch({
													type: Constants.DSA_BINARY,
													payload: e.target.value,
												})
											}
										>
											<FormControlLabel
												value="presence"
												control={<Radio />}
												label="Presence"
											/>
											<FormControlLabel
												value="absence"
												control={<Radio />}
												label="Absence"
											/>
										</RadioGroup>
									) : (
										[]
									)}
								</RadioGroup>
							</>
						) : (
							[]
						)}
					</Grid>
					<Grid className={classes.section}>
						<Grid container direction="row" justify="space-between">
							<b>Histology</b>
							<Checkbox
								checked={
									state.histology_status === "not_available" ? false : true
								}
								inputProps={{ "aria-label": "Checkbox A" }}
								onChange={(e) => {
									if (!e.target.checked) {
										dispatch({
											type: Constants.HISTOLOGY_STATUS,
											payload: "not_available",
										});
									} else {
										if (e.target.checked) {
											dispatch({
												type: Constants.HISTOLOGY_STATUS,
												payload: "banff_available",
											});
										}
									}
								}}
							/>
						</Grid>
						{state.histology_status !== "not_available" ? (
							<>
								<TextField
									name={Constants.DATE_OF_HISTOLOGY_TAKEN}
									label="Date of Histology Collection"
									type="date"
									onChange={(e) => {
										const error = handleChange(
											Constants.DATE_OF_HISTOLOGY_TAKEN,
											e.target.value
										);
										setErrorDateOfHistologyTaken(error);
									}}
									error={errorDateOfHistologyTaken ? true : false}
									helperText={
										errorDateOfHistologyTaken
											? Constants.ERROR_DATE_OF_HISTOLOGY_TAKEN
											: ""
									}
									value={state.date_of_histology_taken}
									className={classes.floatingLabelStyle}
								/>
								<RadioGroup
									onChange={(e) => {
										// nulling out so to not ovewrite db values

										if (e.target.value === "banff_available") {
											dispatch({
												type: Constants.DIAGNOSIS_AKI,
												payload: null,
											});
											dispatch({
												type: Constants.DIAGNOSIS_TCMR,
												payload: null,
											});
											dispatch({
												type: Constants.DIAGNOSIS_RECURRENCE,
												payload: null,
											});
											dispatch({
												type: Constants.DIAGNOSIS_BK,
												payload: null,
											});
											dispatch({
												type: Constants.DIAGNOSIS_CNI,
												payload: null,
											});
											dispatch({
												type: Constants.DIAGNOSIS_AKI,
												payload: null,
											});
											dispatch({
												type: Constants.DIAGNOSIS_OTHER,
												payload: null,
											});

											setErrorDiagnosis(false);
										} else {
											dispatch({
												type: Constants.G_SCORE,
												payload: null,
											});
											dispatch({
												type: Constants.PTC_SCORE,
												payload: null,
											});
											dispatch({
												type: Constants.I_SCORE,
												payload: null,
											});
											dispatch({
												type: Constants.T_SCORE,
												payload: null,
											});
											dispatch({
												type: Constants.CG_SCORE,
												payload: null,
											});
											dispatch({
												type: Constants.IFTA_SCORE,
												payload: null,
											});
										}

										dispatch({
											type: Constants.HISTOLOGY_STATUS,
											payload: e.target.value,
										});
									}}
									value={state.histology_status}
								>
									<FormControlLabel
										value="banff_available"
										control={<Radio />}
										label="Banff Available"
									/>
									{state.histology_status === "banff_available" ? (
										<FormGroup className={classes.subList}>
											<FormControl>
												<InputLabel
													htmlFor="glomerulitis"
													className={classes.selectLabelStyle}
												>
													Glomerulitis (g)
												</InputLabel>
												<Select
													name={Constants.G_SCORE}
													variant="filled"
													value={state.g_score}
													id="glomerulitis"
													onChange={(e) =>
														dispatch({
															type: Constants.G_SCORE,
															payload: e.target.value,
														})
													}
												>
													<MenuItem value="">
														<em>Not Available</em>
													</MenuItem>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
											<FormControl>
												<InputLabel
													htmlFor="peritubular-capillaritis"
													className={classes.selectLabelStyle}
												>
													Peritubular capillaritis (ptc)
												</InputLabel>
												<Select
													name={Constants.PTC_SCORE}
													variant="filled"
													value={state.ptc_score}
													id="peritubular-capillaritis"
													onChange={(e) =>
														dispatch({
															type: Constants.PTC_SCORE,
															payload: e.target.value,
														})
													}
												>
													<MenuItem value="">
														<em>Not Available</em>
													</MenuItem>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
											<FormControl>
												<InputLabel
													htmlFor="intersitial-inflammation"
													className={classes.selectLabelStyle}
												>
													Interstitial inflammation (i)
												</InputLabel>
												<Select
													name={Constants.I_SCORE}
													variant="filled"
													value={state.i_score}
													id="intersitial-inflammation"
													onChange={(e) =>
														dispatch({
															type: Constants.I_SCORE,
															payload: e.target.value,
														})
													}
												>
													<MenuItem value="">
														<em>Not Available</em>
													</MenuItem>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
											<FormControl>
												<InputLabel
													htmlFor="tubulitis"
													className={classes.selectLabelStyle}
												>
													Tubulitis (t)
												</InputLabel>
												<Select
													name={Constants.T_SCORE}
													variant="filled"
													value={state.t_score}
													id="tubulitis"
													onChange={(e) =>
														dispatch({
															type: Constants.T_SCORE,
															payload: e.target.value,
														})
													}
												>
													<MenuItem value="">
														<em>Not Available</em>
													</MenuItem>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
											<FormControl>
												<InputLabel
													htmlFor="transplant-glomerulopathy"
													className={classes.selectLabelStyle}
												>
													Transplant glomerulopathy (cg)
												</InputLabel>
												<Select
													name={Constants.CG_SCORE}
													variant="filled"
													value={state.cg_score}
													id="transplant-glomerulopathy"
													onChange={(e) =>
														dispatch({
															type: Constants.CG_SCORE,
															payload: e.target.value,
														})
													}
												>
													<MenuItem value="">
														<em>Not Available</em>
													</MenuItem>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
											<FormControl>
												<InputLabel
													htmlFor="atrophy-fibrosis"
													className={
														classes.selectLabelStyle + " " + classes.smallLabel
													}
												>
													Atrophy-fibrosis (Cat. 5 IFTA score, based on ci & ct
													lesion scores)
												</InputLabel>
												<Select
													name={Constants.IFTA_SCORE}
													variant="filled"
													value={state.ifta_score}
													id="atrophy-fibrosis"
													onChange={(e) =>
														dispatch({
															type: Constants.IFTA_SCORE,
															payload: e.target.value,
														})
													}
													className={classes.floatingLabelStyle}
												>
													<MenuItem value="">
														<em>Not Available</em>
													</MenuItem>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
										</FormGroup>
									) : (
										[]
									)}
									<FormControlLabel
										value="diagnosis_available"
										control={<Radio />}
										label="Diagnosis Available"
									/>
									{state.histology_status === "diagnosis_available" ? (
										<FormControl className={classes.subList}>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_ABMR}
															checked={
																state.diagnosis_abmr !== true ? false : true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_ABMR,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="ABMR"
												/>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_TCMR}
															checked={
																state.diagnosis_tcmr !== true ? false : true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_TCMR,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="TCMR"
												/>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_RECURRENCE}
															checked={
																state.diagnosis_recurrence !== true
																	? false
																	: true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_RECURRENCE,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="Recurrence"
												/>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_BK}
															checked={
																state.diagnosis_bk !== true ? false : true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_BK,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="BK"
												/>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_CNI}
															checked={
																state.diagnosis_cni !== true ? false : true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_CNI,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="CNI"
												/>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_AKI}
															checked={
																state.diagnosis_aki !== true ? false : true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_AKI,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="AKI"
												/>
												<FormControlLabel
													control={
														<Checkbox
															name={Constants.DIAGNOSIS_OTHER}
															checked={
																state.diagnosis_other !== true ? false : true
															}
															onChange={(e) => {
																dispatch({
																	type: Constants.DIAGNOSIS_OTHER,
																	payload: e.target.checked,
																});
															}}
														/>
													}
													label="Other"
												/>
											</FormGroup>
											<FormHelperText error={errorDiagnosis}>
												Must select at least one
											</FormHelperText>
										</FormControl>
									) : (
										[]
									)}
								</RadioGroup>
							</>
						) : (
							[]
						)}
					</Grid>
					<Button type="submit" disabled={buttonDisabled}>
						{loading ? <CircularProgress color="secondary" /> : "Submit"}
					</Button>
				</form>
			</Grid>
		</Paper>
	);
}

export default IBoxDataPoints;
