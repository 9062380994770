import IBoxDataPoints from "./IBoxDataPoints";
import { CircularProgress } from "@material-ui/core";
import { useState, useEffect } from "react";
import {
	getParameters,
	postParameters,
} from "../../repository/parametersRepository";
import { useHistory } from "react-router-dom";
import useAccessTokenState from "../../common/useAccessTokenState";
import useSearch from "../../common/useSearch";

export default function IBoxDataPointsPage() {
	const { qr: code } = useSearch();
	const {
		accessToken,
		loading: authLoading,
		authenticated,
	} = useAccessTokenState();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [iboxObject, setIboxObject] = useState({ parameters: {} });
	const [successDialogOpen, setSuccessDialogOpen] = useState(false);
	const [alert, setAlert] = useState({
		open: false,
		message: null,
		invocationId: null,
	});

	useEffect(
		function () {
			if (authLoading) {
			} else if (authenticated) {
				(async function () {
					const result = await getParameters(code, accessToken);
					if (result.ok) {
						setIboxObject(result);
					} else if (result.status === 401) {
						// token expired or invalid token
						history.push(`/?qr=${code}`);
					} else {
						setAlert({
							open: true,
							status: result.status,
							message: result.statusText,
							invocationId: result.invocation_id,
						});
					}
					setIsLoading(false);
				})();
			} else {
				history.push(`/?qr=${code}`);
			}
		},
		[accessToken, authLoading, authenticated]
	);

	async function handleSubmit({ event, okraId, state, iBoxParameters }) {
		event.preventDefault();
		setIsSubmitLoading(true);
		const parameters = {
			okra_id: okraId,
			parameters: {
				...state,
				risk_evaluation_date: iBoxParameters.allosure_draw_date,
			},
		};
		const result = await postParameters(code, parameters, accessToken);
		setIsSubmitLoading(false);
		if (result.ok) {
			setSuccessDialogOpen(true);
			setAlert({
				open: false,
			});
		} else {
			setAlert({
				open: true,
				message: "KidneyCare Parameters could not be added.",
				invocationId: result.invocation_id,
			});
			window.scroll(0, 0);
		}
	}

	function handleInvalid(event) {
		event.preventDefault();
		setAlert({
			open: true,
			message: "Please fill out all required fields",
		});
		window.scroll(0, 0);
	}

	let content;
	if (isLoading) {
		content = (
			<>
				<CircularProgress color="secondary" />
				Loading iBox Parameters...
			</>
		);
	} else {
		content = (
			<IBoxDataPoints
				successDialogOpen={successDialogOpen}
				onInvalid={handleInvalid}
				onSubmit={handleSubmit}
				alert={alert}
				loading={isSubmitLoading}
				iBoxParameters={iboxObject}
			/>
		);
	}
	return content;
}
