import { useState, useEffect } from "react";

export default function useAccessTokenState() {
	const [state, setState] = useState({
		accessToken: null,
		loading: true,
		authenticated: false,
	});
	useEffect(function () {
		const accessToken = sessionStorage.getItem("ACCESS_TOKEN");
		setState({
			accessToken,
			loading: false,
			authenticated: !!accessToken,
		});
	}, []);
	
	return {
		accessToken: state.accessToken,
		loading: state.loading,
		authenticated: state.authenticated,
		setAccessToken(accessToken) {
			setState({
				accessToken,
				loading: false,
				authenticated: true,
			});
			sessionStorage.setItem("ACCESS_TOKEN", accessToken);
		},
	};
}
