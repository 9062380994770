function getDateDiffInDays(allosureDrawDate, date) {
	date = new Date(date);
	allosureDrawDate = new Date(allosureDrawDate);
	var diffMilli = Math.abs(allosureDrawDate - date);
	var diffDays = Math.ceil(diffMilli / (1000 * 60 * 60 * 24));
	return diffDays;
}

export function validateDate(allosureDrawDate, date, expectedDiff) {
	const diffDays = getDateDiffInDays(allosureDrawDate, date);
	return diffDays <= expectedDiff;
}

export function validateNumber(value, lowerLimit, upperLimit, decimals) {
	if (isNaN(value)) {
		return false;
	}
	if (lowerLimit !== null && value < lowerLimit) {
		return false;
	}
	if (upperLimit !== null && value > upperLimit) {
		return false;
	}

	if (value.split(".")[1] && value.split(".")[1].length > decimals) {
		return false;
	}

	return true;
}
