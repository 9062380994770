import {
	Paper,
	Button,
	Grid,
	makeStyles,
	CircularProgress,
	createMuiTheme,
	ThemeProvider,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import kidneyCareLogo from "../../assets/kidneyCareLogo.png";
import iboxLogo from "../../assets/iboxLogo2.png";
import careDxLogo from "../../assets/careDxLogo.png";
import { useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
	image: {
		margin: 10,
		alignSelf: "center",
	},

	errorBox: {
		marginLeft: "15px",
		marginRight: "15px",
	},
	dobControl: {
		alignSelf: "center",
	},
});

const calendarTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#3399FF",
		},
	},
});

function DateOfBirth({ loading, alert, handleDateOfBirthSubmit }) {
	const classes = useStyles();
	const [dateOfBirth, setDateOfBirth] = useState(null);

	return (
		<Paper>
			{alert.open ? (
				<Alert variant="filled" severity="error" className={classes.errorBox}>
					<AlertTitle>Error</AlertTitle>
					{alert.status === 400 ? (
						<div>
							DOB entered doesn't match iBox database. Please check and reenter.
						</div>
					) : (
						<div>
							If issue persists, please provide a screenshot of this error
							message to CareDx support.
						</div>
					)}
					<br/>
					<div>Correlation ID: {alert.invocationId} </div>
				</Alert>
			) : (
				[]
			)}
			<Grid>
				<img className={classes.image} src={kidneyCareLogo} />
				<img className={classes.image} width={100} src={iboxLogo} />
				<ThemeProvider theme={calendarTheme}>
					<KeyboardDatePicker
						className={classes.dobControl}
						margin="normal"
						format="MM/DD/YYYY"
						label="Patient DOB"
						value={dateOfBirth}
						maxDate={new Date()}
						placeholder="MM/DD/YYYY"
						onChange={(e) => {
							setDateOfBirth(e.format("YYYY-MM-DD"));
						}}
					/>
				</ThemeProvider>
				<Button
					onClick={() => {
						handleDateOfBirthSubmit(dateOfBirth);
					}}
				>
					{loading ? <CircularProgress color="secondary" /> : "Access"}
				</Button>

				<img className={classes.image} width={100} src={careDxLogo} />
			</Grid>
		</Paper>
	);
}

export default DateOfBirth;
