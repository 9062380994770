export const PATIENT_ID = "patient_id";
export const ALLOSURE_DRAW_DATE = "allosure_draw_date";
export const ACCESSION_ID = "accession_id";
export const OKRA_ID = "okra_id";
export const RISK_EVALUATION_DATE = "risk_evaluation_date";
export const DATE_OF_EGFR_TAKEN = "date_of_egfr_taken";
export const EGFR_STATUS = "egfr_status";
export const EGFR = "egfr";
export const DATE_OF_PROTEINURIA_TAKEN = "date_of_proteinuria_taken";
export const PROTEINURIA_UNIT = "proteinuria_unit";
export const PROTEINURIA_IN_G_G = "proteinuria_in_g_g";
export const PROTEINURIA_DIPSTICK = "proteinuria_dipstick";
export const DATE_OF_DSA_TAKEN = "date_of_dsa_taken";
export const DSA_STATUS = "dsa_status";
export const DSA_MFI = "dsa_mfi";
export const DSA_BINARY = "dsa_binary";
export const DATE_OF_HISTOLOGY_TAKEN = "date_of_histology_taken";
export const HISTOLOGY_STATUS = "histology_status";
export const DIAGNOSIS_ABMR = "diagnosis_abmr";
export const DIAGNOSIS_TCMR = "diagnosis_tcmr";
export const DIAGNOSIS_RECURRENCE = "diagnosis_recurrence";
export const DIAGNOSIS_BK = "diagnosis_bk";
export const DIAGNOSIS_CNI = "diagnosis_cni";
export const DIAGNOSIS_AKI = "diagnosis_aki";
export const DIAGNOSIS_OTHER = "diagnosis_other";
export const AGE = "age";
export const IS_FEMALE = "is_female";
export const IS_BLACK = "is_black";
export const CREATININE = "creatinine";
export const G_SCORE = "g_score";
export const PTC_SCORE = "ptc_score";
export const I_SCORE = "i_score";
export const T_SCORE = "t_score";
export const CG_SCORE = "cg_score";
export const IFTA_SCORE = "ifta_score";

export const ERROR_DATE_OF_EGFR_TAKEN =
	"Date of Blood Draw must be within 7 days of AlloSure";
export const ERROR_DATE_OF_PROTEINURIA_TAKEN =
	"Date of Urine Collection must be within 7 days of AlloSure";
export const ERROR_DATE_OF_DSA_TAKEN =
	"Date of DSA Taken must be within 31 days of AlloSure";
export const ERROR_DATE_OF_HISTOLOGY_TAKEN =
	"Date of Histology Taken must be within 31 days of AlloSure";
export const ERROR_EGFR = "eGFR value must be 0.0-120.0";
export const ERROR_CREATININE =
	"Serum Creatinine should be mg/dL. Max 2 decimals. Numerical only, no symbols.";
export const ERROR_PROTEINURIA_IN_G_G = "PCR value must be 0-12";
export const ERROR_DSA_MFI = "MFI value must be 0-20000";
