import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogContentText,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function SuccessModal(props) {
	return (
		<Dialog open={props.open}>
			<>
				<CheckCircleIcon color="action" fontSize="large" />
				<DialogTitle>Thank you for the data entry!</DialogTitle>
				<DialogContent>
					<DialogContentText>iBox report is in process.</DialogContentText>
					<DialogContentText>
						Thank you for your input. An updated report will be sent to you
						shortly. Please close this tab before scanning another QR code.
					</DialogContentText>
				</DialogContent>
			</>
		</Dialog>
	);
}
export default SuccessModal;
