import moment from 'moment';

const url =
	process.env.NODE_ENV === "development"
		? "https://fnapp-ibox-pep-service.azurewebsites.net/api"
		: "/api";

export async function dobVerify(qr, dob) {
	const path = `${url}/verifydob`;
	const response = await fetch(path, {
		headers: {
			"Content-Type": "application/json",
		},
		method: "POST",
		body: JSON.stringify({
			code: qr,
			dob: dob,
		}),
	});

	const json = await response.json();
	if (response.ok) {
		return {
			...json,
			ok: true,
		};
	} else {
		return {
			...json,
			status: response.status,
			statusText: response.statusText,
		};
	}
}

export async function postParameters(qr, parameters, token) {
	var path = `${url}/parameters?qr_code=${qr}`;
	let response = await fetch(path, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(parameters),
	});

	if (response.ok) {
		return {
			ok: response.ok,
		};
	} else {
		const json = await response.json();
		return {
			...json,
			status: response.status,
			statusText: response.statusText,
		};
	}
}

export async function getParameters(qr, token) {
	const path = `${url}/parameters?qr_code=${qr}`;
	const response = await fetch(path, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	
	const text = await response.text();
	if (response.ok) {
		const json = JSON.parse(
			text,
			function reviveDates(prop, value) {
				if (prop.includes("date") && !!value) {
					const stripped = moment(value).utc().format("YYYY-MM-DD");
					return stripped;
				} 
				else {
					return value;
				}
			});
		return {
			...json,
			ok: true,
		};
	} else {
		const json = JSON.parse(text);
		return {
			...json,
			status: response.status,
			statusText: response.statusText,
		};
	}
}
