import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
	props: {
		MuiDialogTitle: {
			style: {
				color: "white",
			},
		},
		MuiDialogContentText: {
			style: {
				color: "white",
			},
		},
		MuiDialog: {
			style: {
				textAlign: "center",
			},
			PaperProps: {
				style: {
					backgroundColor: "black",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					paddingTop: "20px",
				},
			},
		},
		MuiButton: {
			variant: "contained",
			color: "primary",
			fullWidth: true,
			style: {
				marginTop: "20px",
			},
		},
		MuiTextField: {
			variant: "filled",
			fullWidth: true,
			margin: "dense",
			InputLabelProps: {
				shrink: true,
			},
		},
		MuiFormHelperText: {
			style: {
				marginLeft: "0px",
				marginRight: "0px",
			},
		},
		MuiPaper: {
			elevation: 10,
			style: {
				marginTop: "30px",
			},
		},
		MuiGrid: {
			direction: "column",
			container: true,
			alignItems: "flex-start",
			style: {
				padding: "20px",
			},
		},
		MuiRadio: {
			color: "secondary",
		},
		MuiFormGroup: {
			style: {
				width: "100%",
			},
		},
		MuiSelect: {
			margin: "dense",
		},

		MuiFormLabel: {
			style: {
				padding: "5px",
			},
		},

		MuiFormControl: {
			style: {
				width: "100%",
			},
			margin: "dense",
		},
		MuiInputAdornment: {
			style: {
				paddingRight: "50px",
			},
		},
	},
	palette: {
		primary: {
			main: "#cb333b",
		},
		secondary: {
			main: "#000000",
		},
		action: {
			active: "#007aff",
			selected: "#007aff",
		},
	},
});
