import { useLocation } from 'react-router-dom';

export default function useSearch() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramsList = Array.from(params);
    const paramObj = {}
    for (let p = 0; p < paramsList.length; p++) {
        const paramP = paramsList[p];
        paramObj[paramP[0]] = paramP[1];
    }
    return paramObj;
}