import "./App.css";
import DateOfBirthPage from "./pages/DateOfBirthEntry/DateOfBirthPage";
import IBoxDataPointsPage from "./pages/DataEntry/IBoxDataPointsPage";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<ThemeProvider theme={theme}>
					<Router>
						<Switch>
							<Route path="/DataEntry" component={IBoxDataPointsPage} />
							<Route exact path="/" component={DateOfBirthPage} />
						</Switch>
					</Router>
				</ThemeProvider>
			</header>
		</div>
	);
}

export default App;
